<template>
  <card-component title="Change plan" icon="credit-card">
    <div v-if="user.plan === 'free'">
      <stripe-billing />
      <FAQ class="section" />
    </div>
    <div v-else>
      <stripe-change />
      <FAQ class="section" />
      <b-field horizontal label="Cancel your subscription">
        <b-button type="is-danger" @click="startCancel()">
          Cancel
        </b-button>
        <b-modal :active.sync="isCardModalActive">
          <div class="card">
            <div class="card-image">
              <figure class="image is-16by9">
                <img src="../../assets/quit.gif" alt="Image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="content">
                <div class="columns is-mobile">
                  <div class="column">
                    Are you sure?
                    <br />
                    <span v-if="empty === true" class="has-text-danger">
                      Please select a reason
                    </span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-dropdown v-model="text" aria-role="list">
                      <button
                        v-if="text === ''"
                        slot="trigger"
                        class="button is-primary"
                        type="button"
                      >
                        <span>Select a reason</span>
                        <b-icon icon="menu-down" />
                      </button>
                      <button v-else slot="trigger" class="button is-primary" type="button">
                        <span>{{ text }}</span>
                        <b-icon icon="menu-down" />
                      </button>
                      <b-dropdown-item value="Not what I was looking for" aria-role="listitem">
                        <span>Not what I was looking for</span>
                      </b-dropdown-item>
                      <b-dropdown-item value="The platform is too expensive" aria-role="listitem">
                        <span>The platform is too expensive</span>
                      </b-dropdown-item>

                      <b-dropdown-item value="I don't have use for it now" aria-role="listitem">
                        <span>I don't have use for it now</span>
                      </b-dropdown-item>
                      <b-dropdown-item value="The data is not accurate" aria-role="listitem">
                        <span>The data is not accurate</span>
                      </b-dropdown-item>
                      <b-dropdown-item value="I found a better tool" aria-role="listitem">
                        <span>I found a better tool</span>
                      </b-dropdown-item>
                      <b-dropdown-item value="Other" aria-role="listitem">
                        <span>Other</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="columns">
                  <div class="column has-text-right">
                    <b-button class="has-margin-right-5" @click="isCardModalActive = false">
                      Back
                    </b-button>
                    <b-button v-if="text === ''" type="is-danger" @click="empty = true">
                      Cancel your subscription
                    </b-button>
                    <b-button v-else type="is-danger" @click="save()">
                      Cancel your subscription
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </b-field>
    </div>
  </card-component>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import axios from "axios";
import CardComponent from "@/components/CardComponent";
import StripeBilling from "@/components/stripe/StripeBilling";
import StripeChange from "@/components/stripe/StripeChange";
import FAQ from "@/components/stripe/FAQ";

export default {
  components: {
    CardComponent,
    StripeBilling,
    StripeChange,
    FAQ,
  },
  data() {
    return {
      isCardModalActive: false,
      text: "",
      empty: false,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  methods: {
    save() {
      firebase
        .firestore()
        .collection("cancel")
        .add({
          email: this.user.email,
          time: new Date(),
          text: this.text,
        });
      const eventProperties = {
        reason: this.text,
      };
      this.$amplitude.logEvent("user has canceled subscription", eventProperties);
      axios
        .post(
          `${process.env.VUE_APP_STRIPE_SERVICE}/cancel?token=${process.env.VUE_APP_AUTH_TOKEN}`,
          {
            email: this.user.email,
            customer_id: this.user.stripe_id,
            subscription_id: this.user.subscription_id,
          }
        )
        .then(() => {
          this.$buefy.toast.open(
            "Your subscription is cancelled. You still have access to your premium plan until the end of your subscription cycle."
          );
          this.isCardModalActive = false;
          this.text = "";
          this.$store.dispatch("user/updateUsersetting");
        })
        .catch(() => {
          this.$buefy.toast.open("Something went wrong");
        });
    },
    startCancel() {
      this.$amplitude.logEvent("user has started cancelation subscription");
      this.isCardModalActive = true;
    },
  },
};
</script>
