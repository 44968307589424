<template>
  <div>
    <div v-if="user.old_pricing === true" class="columns is-centered">
      <div class="column is-half">
        <b-message title="New pricing!" type="is-primary" aria-close-label="Close message">
          We have updated our pricing to add bigger plan. You will keep your old plan as long as you
          want or you can update to a new plan.
        </b-message>
      </div>
    </div>
    <div v-if="user.has_cancelled === true" class="columns is-centered">
      <div class="column is-half">
        <b-message
          title="Subscription Cancelled!"
          type="is-primary"
          aria-close-label="Close message"
        >
          Your subscription is cancelled, but you can still use your account until the end of your
          current billing period.
        </b-message>
      </div>
    </div>
    <p class="has-text-centered is-size-7 has-margin-bottom-20">
      Save up to 20% with yearly billing
    </p>
    <b-tabs type="is-toggle-rounded" position="is-centered" size="is-small">
      <b-tab-item label="Monthly">
        <div class="columns">
          <div class="column is-3 is-offset-2">
            <div class="box">
              <p class="subtitle has-text-primary">
                Starter
              </p>
              <p class="subtitle">
                up to
                <span class="title">5</span> trackers
                <br />
                <!-- <span class="is-size-7">to track anything</span> -->
              </p>

              <p class>
                <span class="has-text-weight-bold">29€</span>/Mo
                <br />
                <span class="is-size-7">Billed monthly</span>
              </p>
              <div class="has-margin-top-15">
                <b-button
                  v-if="user.plan === 'starter' && user.old_pricing !== true"
                  type="is-primary"
                  disabled
                >
                  Current plan
                </b-button>
                <b-button
                  v-else
                  :disabled="loading"
                  type="is-primary"
                  @click="submit(pricing.starter.monthly)"
                >
                  Select plan
                </b-button>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <div class="box">
              <p class="subtitle has-text-primary">
                Growth
              </p>
              <p class="subtitle">
                up to
                <span class="title">20</span> trackers
                <br />
                <!-- <span class="is-size-7">to track Users, Hashtags, Posts</span> -->
              </p>
              <p class>
                <span class="has-text-weight-bold">109€</span>/Mo
                <br />
                <span class="is-size-7">Billed monthly</span>
              </p>
              <div class="has-margin-top-15">
                <b-button
                  v-if="user.plan === 'growth' && user.old_pricing !== true"
                  type="is-primary"
                  disabled
                >
                  Current plan
                </b-button>
                <b-button
                  v-else
                  :disabled="loading"
                  type="is-primary"
                  @click="submit(pricing.growth.monthly)"
                >
                  Select plan
                </b-button>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <div class="box">
              <p class="subtitle has-text-primary">
                Pro
              </p>
              <p class="subtitle">
                up to
                <span class="title">50</span> trackers
                <br />
              </p>
              <p class>
                <span class="has-text-weight-bold">249€</span>/Mo
                <br />
                <span class="is-size-7">Billed monthly</span>
              </p>
              <div class="has-margin-top-15">
                <b-button
                  v-if="user.plan === 'pro' && user.old_pricing !== true"
                  type="is-primary"
                  disabled
                >
                  Current plan
                </b-button>
                <b-button
                  v-else
                  :disabled="loading"
                  type="is-primary"
                  @click="submit(pricing.pro.monthly)"
                >
                  Select plan
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Yearly">
        <div class="columns">
          <div class="column is-3 is-offset-2">
            <div class="box">
              <p class="subtitle has-text-primary">
                Starter
              </p>
              <p class="subtitle">
                up to
                <span class="title">5</span> trackers
                <br />
                <!-- <span class="is-size-7">to track anything</span> -->
              </p>

              <p class>
                <span class="has-text-weight-bold">25€</span>/Mo
                <br />
                <span class="is-size-7">300€ billed annually</span>
              </p>
              <div class="has-margin-top-15">
                <b-button
                  v-if="user.plan === 'starter-yearly' && user.old_pricing !== true"
                  type="is-primary"
                  disabled
                >
                  Current plan
                </b-button>
                <b-button
                  v-else
                  :disabled="loading"
                  type="is-primary"
                  @click="submit(pricing.starter.yearly)"
                >
                  Select plan
                </b-button>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <div class="box">
              <p class="subtitle has-text-primary">
                Growth
              </p>
              <p class="subtitle">
                up to
                <span class="title">20</span> trackers
                <br />
                <!-- <span class="is-size-7">to track Users, Hashtags, Posts</span> -->
              </p>
              <p class>
                <span class="has-text-weight-bold">90.8€</span>/Mo
                <br />
                <span class="is-size-7">1080€ billed annually</span>
              </p>
              <div class="has-margin-top-15">
                <b-button
                  v-if="user.plan === 'growth-yearly' && user.old_pricing !== true"
                  type="is-primary"
                  disabled
                >
                  Current plan
                </b-button>
                <b-button
                  v-else
                  :disabled="loading"
                  type="is-primary"
                  @click="submit(pricing.growth.yearly)"
                >
                  Select plan
                </b-button>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <div class="box">
              <p class="subtitle has-text-primary">
                Pro
              </p>
              <p class="subtitle">
                up to
                <span class="title">50</span> trackers
                <br />
              </p>
              <p class>
                <span class="has-text-weight-bold">207.5€</span>/Mo
                <br />
                <span class="is-size-7">2490€ billed annually</span>
              </p>
              <div class="has-margin-top-15">
                <b-button
                  v-if="user.plan === 'pro-yearly' && user.old_pricing !== true"
                  type="is-primary"
                  disabled
                >
                  Current plan
                </b-button>
                <b-button
                  v-else
                  :disabled="loading"
                  type="is-primary"
                  @click="submit(pricing.pro.yearly)"
                >
                  Select plan
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Cookies from "js-cookie";
import store from "@/store";
import pricing from "../../lib/stripe-pricing";
import pusher from "../../lib/pusher";

// eslint-disable-next-line
const stripe = Stripe(process.env.VUE_APP_stripe_key);

export default {
  components: {},
  data() {
    return {
      pricing,
      loading: this.loading,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  mounted: () => {
    const pentosUserString = Cookies.get("pentos_user");

    console.log("mounting StripeChange with", pentosUserString);

    if (pentosUserString) {
      const pentosUser = JSON.parse(pentosUserString);
      console.log("pentosUser", pentosUser);

      console.log("pusher subscribing to users channel");
      const usersChannel = pusher.subscribe("users");
      usersChannel.bind(`updated.${pentosUser.uid}`, () => {
        console.log("pusher user updated");
        store.dispatch("user/getUser");
      });
    }
  },
  methods: {
    submit(id) {
      this.$amplitude.logEvent("user has changed plan", { plan: id });

      if (this.user.subscription_id) {
        this.loading = true;
        axios
          .post(
            `${process.env.VUE_APP_STRIPE_SERVICE}/update-subscription?token=${process.env.VUE_APP_AUTH_TOKEN}`,
            {
              plan: id,
              subscription_id: this.user.subscription_id,
            }
          )
          .then(() => {
            this.loading = false;
            this.$buefy.toast.open("Subscription updated");
          })
          .catch(() => {
            this.loading = false;
            this.$buefy.toast.open("Something went wrong");
          });
        return;
      }

      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_STRIPE_SERVICE}/checkout?token=${process.env.VUE_APP_AUTH_TOKEN}`,
          {
            email: this.user.email,
            plan: id,
          }
        )
        .then(response => {
          this.loading = false;
          stripe.redirectToCheckout({
            sessionId: response.data.id,
          });
        })
        .catch(() => {
          this.loading = false;
          this.$buefy.toast.open("Something went wrong");
        });
    },
  },
};
</script>
