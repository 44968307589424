<template>
  <card-component title="Change Password" icon="lock">
    <form @submit.prevent="submit">
      <b-field horizontal label="Current password" message="Required. Your current password">
        <b-input
          v-model="form.password_current"
          name="password_current"
          type="password"
          required
          autcomplete="current-password"
        />
      </b-field>
      <hr />
      <ValidationProvider v-slot="{ errors }" rules="required" vid="password" name="Password">
        <b-field
          horizontal
          label="New password"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            v-model="form.password"
            name="password"
            type="password"
            required
            autocomplete="new-password"
          />
        </b-field>
      </ValidationProvider>
      <br />
      <ValidationProvider v-slot="{ errors }" rules="required|confirmed:password" name="Password">
        <b-field
          horizontal
          label="Confirm password"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            v-model="form.password_confirmation"
            name="password_confirmation"
            type="password"
            autocomplete="new-password"
          />
        </b-field>
      </ValidationProvider>
      <hr />
      <b-field horizontal>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }">
            Submit
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
import { required, confirmed } from "vee-validate/dist/rules";
import CardComponent from "@/components/CardComponent";
import store from "@/store";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("confirmed", {
  ...confirmed,
  message: "This field confirmation does not match",
});

export default {
  name: "PasswordUpdateForm",
  components: {
    CardComponent,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: false,
      form: {
        password_current: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    submit() {
      store.dispatch("auth/passwordChange", {
        password: this.password_current,
        newPassword: this.password,
      });
      // passwordChange params.password params.newPasswordcd
      this.$buefy.snackbar.open(
        {
          message: "Updated",
          queue: false,
        },
        500
      );
    },
  },
};
</script>
