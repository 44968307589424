<template>
  <card-component title="Basic info" icon="account">
    <b-field horizontal label="Your name">
      {{ user.name }}
    </b-field>
    <b-field horizontal label="Number of tracking">
      {{ user.limit }}
    </b-field>
    <b-field horizontal label="Trackers left">
      {{ trackersLeft }}
    </b-field>
  </card-component>
</template>

<script>
import { mapGetters } from "vuex";
import CardComponent from "@/components/CardComponent";

export default {
  components: {
    CardComponent,
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),

    trackersLeft() {
      let avaible = this.user.limit - this.tiktok.length;
      if (avaible > 1) {
        avaible += " trackers";
      } else {
        avaible += " tracker";
      }
      return avaible;
    },
  },
  methods: {},
};
</script>
