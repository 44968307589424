<template>
  <card-component title="Referral Program" icon="account-arrow-left">
    <b-field horizontal label="Your referral code">
      {{ user.referral_code }}
      <b-button
        class="has-margin-left-10"
        type="is-small"
        icon-right="content-copy"
        @click="copyRef"
      />
    </b-field>
    <b-field horizontal label="Your referral link">
      {{ referralLink() }}
      <b-button
        class="has-margin-left-10"
        type="is-small"
        icon-right="content-copy"
        @click="copyLink"
      />
    </b-field>
    <b-field horizontal label="Your referral link">
      <b-collapse aria-id="question1" :open="false">
        <p slot="trigger" slot-scope="props" role="button" aria-controls="question1">
          How does your referral program work ?
          <b-icon type="is-info" :icon="props.open ? 'menu-down' : 'menu-up'" />
        </p>
        <article class="message is-primary">
          <div class="message-body">
            <div class="message-body-content">
              Every time a new user subscribes with your referral code, you both gain a new
              tracking. If you have a free account you can gain up to 10 trackings and 20 trackings
              for a growth account.
            </div>
          </div>
        </article>
      </b-collapse>
    </b-field>
  </card-component>
</template>

<script>
import { mapGetters } from "vuex";
import CardComponent from "@/components/CardComponent";

export default {
  components: {
    CardComponent,
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  methods: {
    referralLink() {
      return `https://app.pentos.co/signup?referral_code=${this.user.referral_code}`;
    },
    copyRef() {
      const vm = this;
      this.$copyText(this.user.referral_code).then(
        e => {
          vm.$buefy.snackbar.open(
            {
              position: "is-top",
              message: "Referral code copy ! Share it",
              queue: false,
            },
            500
          );
        },
        e => {
          console.log(e);
        }
      );
    },
    copyLink() {
      const vm = this;
      this.$copyText(this.referralLink()).then(
        e => {
          vm.$buefy.snackbar.open(
            {
              position: "is-top",
              message: "Referral link copy ! Share it",
              queue: false,
            },
            500
          );
        },
        e => {
          console.log(e);
        }
      );
    },
  },
};
</script>
