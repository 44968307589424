<template>
  <section class="section">
    <basic-info />
    <!-- <notification /> -->
    <stripe />
    <referral />
    <password-update-form />
  </section>
</template>

<script>
import BasicInfo from "@/components/setting/BasicInfo";
// import Notification from "@/components/setting/Notification";

import Referral from "@/components/setting/Referral";

import PasswordUpdateForm from "@/components/setting/PasswordUpdateForm";
import Stripe from "@/components/setting/Stripe";

export default {
  name: "Profile",
  components: {
    BasicInfo,
    // Notification,
    Referral,
    PasswordUpdateForm,
    Stripe,
  },
  computed: {},
  mounted() {
    // get result for data
    this.$store.dispatch("user/getUser");
  },
};
</script>
